<template>
  <div class="my-6 flex h-screen w-11/12 sm:w-8/12 md:w-6/12 m-auto">
    <div class="flex flex-col w-full m-auto">
      <img
        class="w-48 mx-auto"
        :src="require('../../../assets/images/logo.png')"
        alt="Logomarca Colheita de Talentos"
      />
      <div v-if="!isLoading">
        <div v-if="obs">
          <label for="obs" class="mb-2 block text-2xl">Observações:</label>
          <rich-text-editor
            id="obs"
            :editable="false"
            v-model="obs"
            classes="max-h-40"
          ></rich-text-editor>
        </div>
        <div>
          <label for="term" class="mb-2 block text-2xl"
            >Termo de conformidade com a LGPD:</label
          >
          <rich-text-editor
            id="term"
            :editable="false"
            v-model="term"
            classes="h-96 max-h-96"
          ></rich-text-editor>
        </div>

        <div class="my-6 flex justify-between items-center">
          <button
            class="bg-red-500 hover:bg-red-700 border-2 border-red-700 rounded-lg font-bold text-white px-4 py-2"
            type="button"
            @click="refuseTerm"
          >
            Recusar
          </button>
          <button
            class="bg-green-1000 hover:bg-green-1001 border-2 border-green-1001 rounded-lg font-bold text-white px-4 py-2"
            id="term-acept"
            type="button"
            @click="acceptTerm"
          >
            Aceitar
          </button>
        </div>
      </div>
      <div v-else class="text-center">
        Carregando termo, aguarde...
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/utils/axios';
import RichTextEditor from '../../../components/RichTextEditor';
import { mapGetters } from 'vuex';

export default {
  name: 'AcceptTerm',

  components: {
    RichTextEditor
  },

  data() {
    return {
      isLoading: true,
      id: null,
      term: '',
      obs: ''
    };
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    })
  },

  methods: {
    fetchTerm() {
      axios.get('/api/termos/vigente').then(({ data }) => {
        this.id = data.data.id;
        this.term = data.data.term;
        this.obs = data.data.obs;

        this.isLoading = false;
      });
    },

    acceptTerm() {
      document.getElementById('term-acept').disabled = true;
      axios.patch(`/api/termos/${this.id}/aceitar`).then(({ data }) => {
        this.$store.commit('auth/SET_ACCEPTED_CURRENT_TERM', true);

        if (
          this.user.role.name === 'participant' ||
          this.user.role.name === 'Instructor'
        ) {
          this.$router.push({ name: 'my-complete-curriculum' });
        } else {
          this.$router.push({ name: 'search' });
        }

        this.$toast.success(data.data.message);
      });
    },

    refuseTerm() {
      axios.patch(`/api/termos/${this.id}/recusar`).then(({ data }) => {
        this.$toast.warning(data.data.message);

        this.$store.dispatch('auth/logout').then(() => {
          this.$router.push({ name: 'login' });
        });
      });
    }
  },

  created() {
    this.fetchTerm();
  }
};
</script>

<style scoped></style>
